import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/pages/Home.vue";
import store from "../store/index";
import { Page } from "@/modules/apps/page";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/outgoing",
    name: "Outgoings",
    component: () => import("../views/pages/Outgoing.vue"),
  },
  {
    path: "/incoming",
    name: "Incoming",
    component: () => import("../views/pages/Incoming.vue"),
  },
  {
    path: "/incoming/manual-incoming",
    name: "ManualIncoming",
    component: () => import("../views/pages/ManualIncoming.vue"),
  },
  {
    path: "/incoming/:id",
    name: "Incomings",
    component: () => import("../views/pages/IncomingProducts.vue"),
    meta: { transitionName: "zoom", type: "income" },
  },
  {
    path: "/outgoing/problems",
    name: "Problems",
    component: () => import("../views/pages/Problems.vue"),
  },
  {
    path: "/outgoing/inventory-audit",
    name: "inventoryAudit",
    component: () => import("../views/pages/inventoryAudit.vue"),
  },
  {
    path: "/outgoing/inventory-shortage",
    name: "inventoryShortage",
    component: () => import("../views/pages/inventoryShortage.vue"),
  },
  {
    path: "/outgoing/:id",
    name: "Outgoing",
    component: () => import("../views/pages/IncomingProducts.vue"),
    meta: { transitionName: "zoom", type: "outgoing" },
  },
  {
    path: "/sales",
    name: "Sales",
    component: () => import("../views/pages/Sales.vue"),
    meta: { tagType: "orders" },
  },
  {
    path: "/sales/products",
    name: "Sales products",
    component: () => import("../views/pages/SalesProductList.vue"),
    meta: { type: "sales" },
  },
  {
    path: "/sales/return",
    name: "Return products",
    component: () => import("../views/pages/SalesProductList.vue"),
    meta: { type: "return" },
  },
  {
    path: "/sales/collects",
    name: "Sales collects",
    component: () => import("../views/pages/SalesCollectList.vue"),
  },
  {
    path: "/sales/user/:user",
    name: "Sales user",
    component: () => import("../views/pages/Sales.vue"),
  },
  {
    path: "/sales/:id",
    name: "Order",
    component: () => import("../views/pages/Order.vue"),
    meta: { transitionName: "zoom" },
  },
  {
    path: "/session/:id",
    name: "Session",
    component: () => import("../views/pages/Session.vue"),
  },
  {
    path: "/finance",
    name: "Finance",
    component: () => import("../views/pages/Finance.vue"),
  },
  {
    path: "/finance/sales",
    name: "Finance sales",
    component: () => import("../views/pages/FinanceSales.vue"),
  },
  {
    path: "/finance/payments",
    name: "Payments",
    component: () => import("../views/pages/Payments.vue"),
  },
  {
    path: "/finance/payments/:user",
    name: "Payments user",
    component: () => import("../views/pages/Payments.vue"),
  },
  {
    path: "/products",
    name: "Products",
    component: () => import("../views/pages/Products.vue"),
    meta: { tagType: "products" },
  },
  {
    path: "/crm/partners",
    name: "partners",
    component: () => import("../views/pages/Crm.vue"),
    meta: { type: "partner" },
  },
  {
    path: "/crm/team-list",
    name: "members",
    component: () => import("../views/pages/Crm.vue"),
    meta: { type: "team" },
  },
  {
    path: "/crm/customers",
    name: "Customers",
    component: () => import("../views/pages/Crm.vue"),
    meta: { type: "customer" },
  },
  {
    path: "/crm/messages",
    name: "Messages",
    component: () => import("../views/pages/Messages.vue"),
  },
  {
    path: "/materials/:status",
    name: "Materials",
    component: () => import("../views/pages/Products.vue"),
    meta: { type: "materials", tagType: "products" },
  },
  {
    path: "/products/related",
    name: "Related product groups",
    component: () => import("../views/pages/RelatedProductGroups.vue"),
  },
  {
    path: "/products/related/:id",
    name: "Related products",
    component: () => import("../views/pages/RelatedProducts.vue"),
  },
  {
    path: "/products/:status",
    name: "Products",
    component: () => import("../views/pages/Products.vue"),
    meta: { type: "products", tagType: "products" },
  },
  {
    path: "/products/:id/history",
    name: "Product history",
    component: () => import("../views/pages/ProductHistory.vue"),
  },
  {
    path: "/products/:id/history/:variant_id",
    name: "Product variant history",
    component: () => import("../views/pages/ProductHistory.vue"),
  },
  {
    path: "/products/:id/forecast",
    name: "Product forecast",
    component: () => import("../views/pages/ProductForecast.vue"),
  },
  {
    path: "/products/:id/forecast/:variant_id",
    name: "Product variant forecast",
    component: () => import("../views/pages/ProductForecast.vue"),
  },
  {
    path: "/analytics/web",
    name: "Web analytics",
    component: () => import("../views/pages/WebAnalytics.vue"),
  },
  {
    path: "/analytics/inventory",
    name: "Inventory analytics",
    component: () => import("../views/pages/InventoryAnalytics.vue"),
  },
  {
    path: "/analytics/forecast",
    name: "Forecast",
    component: () => import("../views/pages/Forecast.vue"),
  },
  {
    path: "/analytics/stock/value",
    name: "Stock report",
    component: () => import("../views/pages/StockValueReports.vue"),
  },
  {
    path: "/analytics/stock/:type",
    name: "Stock reports",
    component: () => import("../views/pages/StockReports.vue"),
  },
  {
    path: "/production",
    name: "Production grant",
    component: () => import("../views/pages/ManufacturingGrant.vue"),
  },
  {
    path: "/production/daily",
    name: "Production daily list",
    component: () => import("../views/pages/ManufacturingDailyList.vue"),
  },
  {
    path: "/production/collecting/:step/:id",
    name: "Incoming production collect list",
    component: () => import("../views/pages/ManufacturingCollectList.vue"),
  },
  {
    path: "/production/collecting/:step",
    name: "Production collect list",
    component: () => import("../views/pages/ManufacturingCollectList.vue"),
  },
  {
    path: "/production/designer",
    name: "Production designer",
    component: () => import("../views/pages/ManufacturingDesigner.vue"),
  },
  {
    path: "/production/materials",
    name: "Production materials",
    component: () => import("../views/pages/ManufacturingMaterials.vue"),
  },
  {
    path: "/production/operations",
    name: "Production operations",
    component: () => import("../views/pages/Operations.vue"),
  },
  {
    path: "/production/:id",
    name: "Production view",
    component: () => import("../views/pages/IncomingProducts.vue"),
    meta: { transitionName: "zoom", type: "income", view: "production" },
  },
  {
    path: "/assets",
    name: "Assets",
    component: () => import("../views/pages/Assets.vue"),
  },
  {
    path: "/work/buckets/:id",
    name: "Tasks",
    component: () => import("../views/pages/work/Tasks.vue"),
    meta: { transitionName: "zoom" },
  },
  {
    path: "/work/buckets",
    name: "Buckets",
    component: () => import("../views/pages/work/Buckets.vue"),
  },
  {
    path: "/work/tasks/history",
    name: "Work history",
    component: () => import("../views/pages/work/LocalTiming.vue"),
  },
  {
    path: "/integrations",
    name: "Integrations statistics",
    component: () => import("../views/pages/integrations/Statistics.vue"),
  },
  {
    path: "/settings",
    name: "Settings",
    component: () => import("../views/pages/Settings.vue"),
  },
  {
    path: "/integrations/auth/:type",
    name: "Integration Auth",
    component: () => import("../views/pages/IntegrationAuth.vue"),
  },
  {
    path: "/login",
    name: "Login",
    abstract: true,
    props: { lastUserName: "Robert" },
    component: () => import("../views/login/Login.vue"),
  },
  {
    path: "/first-steps",
    name: "FirstSteps",
    abstract: true,
    component: () => import("../views/login/FirstSteps.vue"),
  },
  {
    path: "/forgotten-password",
    name: "ForgottenPassword",
    props: { lastUserName: "Robert" },
    component: () => import("../views/login/ForgottenPassword.vue"),
  },
  {
    path: "/forgotten-password/:hash",
    name: "SetNewPassword",
    props: { lastUserName: "Robert" },
    component: () => import("../views/login/SetNewPassword.vue"),
  },
  {
    path: "/register/:hash",
    name: "Register",
    component: () => import("../views/login/Register.vue"),
  },
  {
    path: "/accept-login/:hash",
    name: "AcceptLogin",
    component: () => import("../views/login/AcceptLogin.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import("../views/errors/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const user = store.state.logged;

  if (
    !user &&
    to.name !== "Login" &&
    to.name !== "ForgottenPassword" &&
    to.name !== "SetNewPassword" &&
    to.name !== "Register"
  ) {
    next({ name: "Login" });
  } else if (
    user &&
    (to.name === "Login" ||
      (to.name === "ForgottenPassword" &&
        to.name === "SetNewPassword" &&
        to.name === "Register"))
  ) {
    next({ name: "Home" });
  } else {
    next();
  }
});

router.afterEachMethod = function (to) {
  store.commit("clearListOption");
  const pageOption = new Page(to.name);
  store.commit("setPageOption", pageOption);
  pageOption.subscribe(
    "addListOption",
    function (text, icon, onClick, always = false) {
      store.commit("addListOption", {
        title: text,
        icon: icon,
        always: always,
        onClick: onClick,
      });
    }
  );
  window.apps.callEvent(to.name + ":list", pageOption);
  window.apps.callEvent("list", pageOption);
};

router.afterEach((to) => {
  router.afterEachMethod(to);
});

export default router;
