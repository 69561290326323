<template>
  <button
    class="btn btn-secondary btn-sm"
    :title="$t(`editOnClick.edit`)"
    v-if="type === 'wysiwyg'"
    v-on:click="edit = true"
    v-on:keyup.enter="edit = true"
  >
    <BaseIcon name="edit-sqare" color="var(--black)" />
  </button>
  <div
    v-else-if="type === 'tags'"
    class="d-flex gap-1"
    v-on:click="focus"
    v-on:keyup.enter="focus"
  >
    <span class="badge" v-if="modelValue?.length > 0">
      {{ modelValue[0].name }}
    </span>
    <span class="badge" v-if="modelValue?.length > 1">
      {{ modelValue[1].name }}
    </span>
    <span class="badge" v-if="modelValue?.length === 3">
      {{ modelValue[2].name }}
    </span>
    <span class="badge" v-if="modelValue?.length > 3">
      {{ $t(`editOnClick.more`, { expr: modelValue?.length - 2 }) }}
    </span>
    <span
      v-if="
        modelValue === null ||
        modelValue === undefined ||
        modelValue?.length === 0
      "
      class="ms-1"
    >
      -
    </span>
  </div>
  <label
    v-else-if="type === 'boolean'"
    class="cstm-check"
    :class="{
      waiting:
        modelValue?.multiple &&
        ((Object.keys(modelValue?.values)?.indexOf('0') ?? -1) > -1 ||
          (Object.keys(modelValue?.values)?.indexOf('null') ?? -1) > -1),
    }"
  >
    <input
      type="checkbox"
      :value="1"
      :checked="
        modelValue &&
        (!modelValue?.multiple ||
          ((Object.keys(modelValue?.values)?.indexOf('0') ?? -1) < 0 &&
            (Object.keys(modelValue?.values)?.indexOf('null') ?? -1) < 0))
      "
      v-on:change="changeBool($event.currentTarget.checked ? 1 : 0)"
      :disabled="editable === false"
    />
    <span class="checkmark"></span>
  </label>
  <span v-else-if="type === 'url' && !edit" class="px-3">
    <a
      :href="modelValue"
      v-if="modelValue !== null && modelValue !== undefined"
      target="_blank"
      class="btn btn-secondary btn-sm"
    >
      <BaseIcon name="outgoing" color="var(--black)" />
    </a>
    <button
      class="btn btn-secondary btn-sm"
      :title="$t(`editOnClick.edit`)"
      v-if="editable !== false"
      v-on:click="focus"
      v-on:keyup.enter="focus"
    >
      <BaseIcon name="edit" color="var(--black)" />
    </button>
  </span>
  <button
    v-else-if="type === 'price'"
    v-on:dblclick="focusClickPosition"
    v-on:keyup.enter="focusClickPosition"
    class="btn-transparent"
    :disabled="editable === false"
    :class="padding ? 'px-3' : ''"
  >
    {{
      $t(`cataloge.key`, {
        expr:
          modelValue === undefined ||
          modelValue?.amount === undefined ||
          modelValue?.currency === undefined ||
          modelValue === null ||
          modelValue?.amount === null ||
          modelValue?.currency === null ||
          modelValue?.amount === ""
            ? " - "
            : typeof modelValue === "object" &&
              modelValue?.multiple !== undefined
            ? "(multiple)"
            : modelValue?.amount?.toLocaleString("hu-HU", {
                style: "currency",
                currency: modelValue?.currency,
                minimumFractionDigits: 0,
                maximumFractionDigits:
                  modelValue?.fractions ??
                  (modelValue?.currency === "HUF"
                    ? 0
                    : modelValue?.currency === "BTC"
                    ? 6
                    : 2),
              }),
      })
    }}
  </button>
  <button
    v-else-if="type === 'purchasePrice'"
    v-on:dblclick="focusClickPosition"
    v-on:keyup.enter="focusClickPosition"
    class="btn-transparent"
    :disabled="editable === false"
    :class="padding ? 'px-3' : ''"
  >
    {{
      $t(`cataloge.key`, {
        expr:
          modelValue === undefined ||
          modelValue === null ||
          modelValue?.length === 0 ||
          modelValue[0]?.amount === undefined ||
          modelValue[0]?.currency === undefined ||
          modelValue[0]?.amount === null ||
          modelValue[0]?.currency === null ||
          modelValue[0]?.amount === ""
            ? " - "
            : typeof modelValue === "object" &&
              modelValue?.multiple !== undefined
            ? "(multiple)"
            : modelValue[0]?.amount?.toLocaleString("hu-HU", {
                style: "currency",
                currency: modelValue[0]?.currency,
                minimumFractionDigits: 0,
                maximumFractionDigits:
                  modelValue[0]?.fractions ??
                  (modelValue[0]?.currency === "HUF"
                    ? 2
                    : modelValue[0]?.currency === "BTC"
                    ? 6
                    : 4),
              }),
      })
    }}
  </button>
  <button
    v-else-if="type === 'bankAccount'"
    v-on:dblclick="focus"
    v-on:keyup.enter="focus"
    class="px-3 btn-transparent"
    :disabled="editable === false"
  >
    {{
      $t(`editOnClick.key3`, {
        currencyIndex: currencyIndex,
        expr:
          modelValue === undefined ||
          modelValue === null ||
          modelValue.length === 0
            ? "-"
            : modelValue[currencyIndex]?.number !== undefined &&
              modelValue[currencyIndex]?.number !== null
            ? modelValue[currencyIndex]?.number
            : modelValue[currencyIndex]?.iban,
      })
    }}
  </button>
  <!--  <span v-else-if="type === 'tax'" v-on:dblclick="focus" class="px-3">
    {{ modelValue.tax }}
  </span> -->
  <button
    v-else-if="type === 'date'"
    v-on:dblclick="focus"
    v-on:keyup.enter="focus"
    class="px-3 btn-transparent"
    :disabled="editable === false"
    :title="
      modelValue === '' || modelValue === undefined || modelValue === null
        ? '-'
        : formatDate(modelValue)
    "
  >
    {{
      $t(`cataloge.key`, {
        expr:
          modelValue === "" || modelValue === undefined || modelValue === null
            ? " - "
            : formatDate(modelValue, false, false),
      })
    }}
  </button>
  <button
    v-else-if="type === 'address'"
    v-on:dblclick="focus"
    v-on:keyup.enter="focus"
    class="px-3 btn-transparent"
    :disabled="editable === false"
  >
    {{ modelValue?.country }}, {{ modelValue?.city }} {{ modelValue?.street }}
    {{ modelValue?.house }}
  </button>
  <button
    v-else-if="type === 'meta'"
    v-on:click="focus"
    v-on:keyup.enter="focus"
    class="btn btn-secondary btn-sm"
  >
    <BaseIcon name="edit-sqare" color="var(--black)" />
  </button>
  <button
    v-else-if="type === 'colorPicker'"
    v-on:click="focus"
    v-on:keyup.enter="focus"
    class="px-3 btn-transparent"
    :disabled="editable === false"
  >
    <div class="color-circle" :style="{ background: '#' + modelValue }"></div>
  </button>
  <span v-else-if="!edit" class="px-2">
    <button
      v-on:dblclick="focus"
      v-on:keyup.enter="focus"
      class="px-1 btn-transparent"
      :class="{ 'no-decor-break': type === 'number' }"
      :disabled="editable === false"
    >
      <span v-if="prefix">{{ prefix }}</span>
      <span
        v-if="type === 'color' && modelValue?.multiple === undefined"
        :style="'background: ' + colorValue"
        class="color-circle me-1"
      >
      </span>
      <span
        v-if="
          type === 'color' &&
          typeof modelValue === 'object' &&
          modelValue?.multiple !== undefined &&
          modelValue.values !== undefined
        "
        v-html="multipleValues(modelValue.values)"
      ></span>

      <template v-else>
        {{
          modelValue === undefined || modelValue === "" || modelValue === null
            ? " - "
            : typeof modelValue === "object" &&
              modelValue?.multiple !== undefined
            ? modelValue.values !== undefined
              ? multipleValues(modelValue.values)
              : "(multiple variants)"
            : (type === "enum" || type === "color" || type === "category") &&
              typeof modelValue === "number"
            ? enumValue
            : type === "number"
            ? Math.round(modelValue * 100) / 100
            : modelValue
        }}
      </template>
      <span v-if="postfix">{{ postfix }}</span>
    </button>
    <button
      class="btn btn-transparent btn-sm"
      :title="$t(`copyProducts.copy`)"
      v-if="showCopy"
      v-on:click="copyText()"
    >
      <BaseIcon name="copy-success" v-if="copied" />
      <BaseIcon name="copy" v-else />
    </button>
  </span>
  <v-select
    v-if="edit && type === 'enum'"
    v-on:focusout="focusOut"
    v-model="modelValueData"
    :options="values"
    ref="imp2"
    append-to-body
    clearable
    taggable
    :dropdown-should-open="() => edit"
    :create-option="(v) => createOption(v)"
    :reduce="selectKey !== undefined ? (a) => a[selectKey] : undefined"
    :label="label"
  />
  <v-select
    v-else-if="edit && type === 'color'"
    v-on:focusout="focusOut"
    v-model="modelValueData"
    :options="values"
    ref="imp5"
    append-to-body
    taggable
    :dropdown-should-open="() => edit"
    :create-option="(v) => createOption(v)"
    :reduce="selectKey !== undefined ? (a) => a[selectKey] : undefined"
    :label="label"
  >
    <template v-slot:selected-option="option">
      <span :style="'background: #' + option.hex" class="color-circle"> </span>
      <span class="ms-3">
        {{ option.value }}
      </span>
    </template>
    <template v-slot:option="option">
      <span :style="'background: #' + option.hex" class="color-circle"> </span>
      <span class="ms-3">
        {{ option.value }}
      </span>
    </template>
  </v-select>
  <v-select
    v-else-if="edit && type === 'category'"
    v-on:focusout="focusOut"
    v-model="modelValueData"
    :options="values"
    ref="imp4"
    append-to-body
    :dropdown-should-open="() => edit"
    :filterable="true"
    :reduce="selectKey !== undefined ? (a) => a[selectKey] : undefined"
    :label="label"
  >
    <template v-slot:no-options="{ search, searching }">
      <template v-if="searching">
        <div class="m-2">
          <small>
            {{ $t("editOnClick.noResultsFoundFor") }}
            <em>{{ search }}</em
            >.
          </small>
        </div>
        <div class="m-2">
          <button
            class="btn btn-small btn-primary"
            v-on:click="categoryAddModal"
          >
            <small>{{ $t("editOnClick.addCategory") }}</small>
          </button>
        </div>
      </template>
      <em v-else style="opacity: 0.5">{{
        $t("editOnClick.startTypingToSearchForACategory")
      }}</em>
    </template>
    <template v-slot:option="option">
      <div class="small mt-1">{{ option.sub }}</div>
      <b>{{ option.top }}</b>
    </template>
  </v-select>

  <Tags
    v-else-if="edit && type === 'tags'"
    v-model:show="edit"
    :ajaxUrl="ajaxUrl"
    :id="id"
    v-model="modelValueData"
    v-on:change="modelValueDataChange"
  />
  <Price
    v-else-if="edit && type === 'price'"
    v-model:show="edit"
    v-model="modelValueData"
    v-on:change="modelValueDataChange"
    :positionX="clickX - 50"
    :positionY="clickY - 50"
  />
  <PurchasePrices
    v-else-if="edit && type === 'purchasePrice'"
    v-model:show="edit"
    v-model="modelValueData"
    v-on:change="modelValueDataChange"
    :positionX="clickX - 50"
    :positionY="clickY - 50"
  />
  <BankAccount
    v-else-if="edit && type === 'bankAccount'"
    v-model:show="edit"
    v-model="modelValueData"
    v-on:change="modelValueDataChange"
  />
  <Address
    v-else-if="edit && type === 'address'"
    v-model:show="edit"
    v-model="modelValueData"
    v-on:change="modelValueDataChange"
  />
  <Meta
    v-else-if="edit && type === 'meta'"
    v-model:show="edit"
    :ajaxUrl="ajaxUrl"
    :id="id"
    v-model="modelValueData"
    v-on:change="modelValueDataChange"
  />
  <DatePicker
    v-else-if="edit && type === 'date'"
    v-model:showPicker="edit"
    :onlyPicker="true"
    v-model="modelValueData"
    :onlyInRange="0"
    :showTime="true"
    :only-one="true"
    v-on:change="modelValueDataChange"
  />
  <InvoiceData
    v-else-if="edit && type === 'tax'"
    v-model:show="edit"
    v-model="modelValueData"
    v-on:change="modelValueDataChange"
  />
  <WysiwygModal
    v-else-if="edit && type === 'wysiwyg'"
    v-model:show="edit"
    v-model:extraRow="extraRowData"
    v-model="modelValueData"
    :ajaxUrl="ajaxUrl"
    :id="id"
    v-on:change="modelValueDataChange"
  />
  <input
    v-else-if="edit"
    v-on:focusout="focusOut"
    v-on:focusin="focusIn"
    :type="type === 'colorPicker' ? 'color' : type"
    v-model="modelValueData"
    ref="imp"
    :style="[
      setWidth ? 'width:' + width : '',
      type === 'colorPicker'
        ? 'overflow:hidden;height:0;padding: 0!important;display:inline;width: 0px;'
        : '',
    ]"
    v-on:input="input($event.target.value)"
    v-on:change="change($event.target.value)"
  />
  <CategoryModal
    v-if="showCategoryAddModal"
    ref="addCategoryModal"
    v-model:selected-language="selectedLanguage"
    :categories="allValues"
    :edit-category="false"
    v-model:active-languages="languages"
    v-on:addNewCategory="addNewCategory"
  />
</template>

<script>
import { defineAsyncComponent } from "vue";
const BaseIcon = defineAsyncComponent(() => import("../icons/BaseIcon.vue"));

const BankAccount = defineAsyncComponent(() => import("./BankAccount.vue"));
const Address = defineAsyncComponent(() => import("./Address.vue"));
const DatePicker = defineAsyncComponent(() => import("./DatePicker.vue"));
const InvoiceData = defineAsyncComponent(() => import("./InvoiceData.vue"));
const Price = defineAsyncComponent(() => import("./Price.vue"));
const Tags = defineAsyncComponent(() => import("./Tags.vue"));
const PurchasePrices = defineAsyncComponent(() =>
  import("./PurchasePrices.vue")
);
const CategoryModal = defineAsyncComponent(() =>
  import("@/components/modals/CategoryModal")
);

const Meta = defineAsyncComponent(() => import("./Meta.vue"));
const WysiwygModal = defineAsyncComponent(() => import("./WysiwygModal.vue"));

import intlTelInput from "intl-tel-input";
import "intl-tel-input/build/css/intlTelInput.css";
import { useStore } from "vuex";
import date from "@/modules/date";
import http from "@/modules/http";

export default {
  name: "EditOnClick",
  components: {
    BaseIcon,
    BankAccount,
    Address,
    DatePicker,
    InvoiceData,
    Price,
    Tags,
    PurchasePrices,
    CategoryModal,
    Meta,
    WysiwygModal,
  },
  props: {
    modelValue: [Object, String, Number],
    type: String,
    editable: {
      type: Boolean,
      default: true,
    },
    padding: {
      type: Boolean,
      default: true,
    },
    setWidth: {
      type: Boolean,
      default: true,
    },
    values: [Object, Array],
    allValues: [Object, Array],
    selectKey: String,
    label: String,
    name: String,
    prefix: String,
    postfix: String,
    ajaxUrl: String,
    id: [Number, String],
    createOption: Function,
    extraRow: [Object, Array, String, Number],
  },
  data() {
    let store = useStore();
    return {
      store: store,
      edit: false,
      width: "40px",
      modelValueData: this.modelValue,
      extraMod: null,
      focusOn: false,
      showCategoryAddModal: false,
      selectedLanguage: "en",
      extraRowData: this.extraRow,
      clickX: 0,
      clickY: 0,
      copied: false,
    };
  },
  computed: {
    mediaServer() {
      return process.env.VUE_APP_MEDIA_URL;
    },
    showCopy() {
      return this.name?.toLowerCase() === "sku";
    },
    path() {
      return this.$route.path;
    },
    languages() {
      const store = useStore();
      return store.state.languages;
    },
    currencyIndex() {
      let i = Object.keys(this.modelValue)[0];
      for (const key in this.modelValue) {
        if (this.modelValue[key].default) {
          i = key;
          break;
        }
      }
      return i;
    },
    colorValue() {
      if (this.values === undefined || this.values === null) {
        return this.modelValue;
      }
      let e =
        this.values?.length > 0
          ? this.values.find((d) => d[this.selectKey] === this.modelValue)
          : this.values[this.modelValue];

      return this.rawColorValue(e);
    },
    enumValue() {
      if (this.values === undefined || this.values === null) {
        return this.modelValue;
      }
      let e =
        this.values?.length > 0
          ? this.values.find((d) => d[this.selectKey] === this.modelValue)
          : this.values[this.modelValue];

      if (
        e !== null &&
        e !== undefined &&
        Object.keys(e).indexOf(this.label) > -1
      ) {
        let val = e[this.label];
        if (val?.length < 1) {
          return "(empty value)";
        }
        return val;
      }

      return this.modelValue;
    },
  },
  emits: [
    "update:modelValue",
    "update",
    "update:allValues",
    "update:values",
    "update:extraRow",
  ],
  watch: {
    extraRowData() {
      this.$emit("update:extraRow", this.extraRowData);
    },
    edit() {
      if (this.modelValue === undefined || this.modelValue === null) {
        this.width = "80px";
        return;
      }

      if (this.edit && this.type === "phone") {
        this.width = this.modelValue?.toString().length * 6 + 120 + "px";
      } else if (
        this.edit &&
        (this.type === "string" || this.type === "number")
      ) {
        this.width = this.modelValue?.toString().length * 6 + 30 + "px";
      }
    },
    modelValue: {
      deep: true,
      handler() {
        this.modelValueData = this.modelValue;
      },
    },
    modelValueData: {
      deep: true,
      handler() {
        /*
        if (this.edit) {
          this.$emit("update:modelValue", this.modelValueData);
          this.$emit("update", this.modelValueData);
        }

        if (
          this.type !== "tags" &&
          this.type !== "bankAccount" &&
          this.type !== "address" &&
          this.type !== "prices" &&
          this.type !== "tax" &&
          this.type !== "meta" &&
          this.type !== "wysiwyg"
        ) {
          this.edit = false;
        }*/
      },
    },
  },
  methods: {
    rawColorValue(e) {
      if (
        e !== null &&
        e !== undefined &&
        Object.keys(e).indexOf("image") > -1
      ) {
        let val = e["image"];
        if (val !== null && val !== undefined) {
          return `url('${this.mediaServer}/color/${e["id"]}/default/32-default.webp')`;
        }
      }

      if (e !== null && e !== undefined && Object.keys(e).indexOf("hex") > -1) {
        let val = e["hex"];
        if (val?.length < 1) {
          return "transparent";
        }
        if (val !== null && val !== undefined) {
          return "#" + val;
        }
      }
      return "transparent";
    },
    copyText() {
      const _this = this;
      navigator.clipboard.writeText(this.modelValue).then(function () {
        _this.copied = true;
        setTimeout(() => {
          _this.copied = false;
        }, 2000);
      });
    },
    categoryAddModal() {
      this.showCategoryAddModal = true;
      this.edit = false;
      const _this = this;
      setTimeout(() => {
        _this.$refs.addCategoryModal.showModal();
      }, 100);
    },
    addNewCategory(addCategory) {
      const _this = this;
      const type = this.path.split("/")[1];

      http
        .fetch(`/products/${type}/categories/add`, addCategory, true)
        .then((data) => {
          this.$refs.addCategoryModal.hideModal();
          setTimeout(() => {
            _this.showCategoryAddModal = false;
          }, 800);

          let str = this.allValues.filter((e) => e.id === addCategory.parent);
          if (str.length > 0) {
            str = str[0].name + " - ";
          } else {
            str = "";
          }

          let allValues = this.allValues;
          let values = this.values;
          allValues.unshift({
            name: `${str}${data.name}`,
            top: data.name,
            sub: str,
            id: data.id,
          });
          values.unshift({
            name: `${str}${data.name}`,
            top: data.name,
            sub: str,
            id: data.id,
          });
          this.$emit("update:allValues", allValues);
          this.$emit("update:values", values);

          this.modelValueData = data.id;
          this.$emit("update:modelValue", this.modelValueData);
          this.$emit("update", this.modelValueData);
        });
    },

    multipleValues(val) {
      let list = [];
      for (const key in val) {
        if (val[key].count < 1) {
          continue;
        }
        let valElement = val[key].value;
        if (
          valElement === undefined ||
          valElement === "" ||
          valElement === null
        ) {
          list.push(" - ");
        } else if (this.type === "color") {
          const _this = this;
          let k = this.values.find((d) => d[this.selectKey] === valElement);
          if (k !== undefined && Object.keys(k).indexOf(this.label) > -1) {
            list.push(
              `<span style="background: ${_this.rawColorValue(
                k
              )}" class="color-circle me-1"></span><span>${k.value}</span>`
            );
          }
        } else if (
          (this.type === "enum" || this.type === "category") &&
          typeof valElement === "number" &&
          this.values?.length > 0
        ) {
          let k = this.values.find((d) => d[this.selectKey] === valElement);
          if (k !== undefined && Object.keys(k).indexOf(this.label) > -1) {
            list.push(k[this.label]);
          }
        } else if (this.type === "number") {
          list.push(Math.round(valElement * 100) / 100);
        } else if (typeof valElement !== "object") {
          list.push(valElement);
        }
      }

      if (list?.length < 0) {
        list.push("(multiple variants)");
      }

      if (list.length > 3) {
        const all = list.length;
        list = list.slice(0, 2);
        list.push(`+${all - 2} more`);
      }

      return list.join(", ");
    },
    focusIn() {
      this.focusOn = true;
    },
    focusOut() {
      this.focusOn = false;
      if (this.type === "phone") {
        const _this = this;
        setTimeout(() => {
          if (!_this.focusOn) {
            if (_this.type === "phone" && _this.extraMod) {
              _this.modelValueData = _this.extraMod.getNumber();
              _this.extraMod.destroy();
              _this.extraMod = null;
            }
            _this.$emit("update:modelValue", _this.modelValueData);
            _this.$emit("update", _this.modelValueData);
            _this.edit = false;
          }
        }, 400);
      } else {
        this.$emit("update:modelValue", this.modelValueData);
        this.$emit("update", this.modelValueData);
        this.edit = false;
      }
    },
    focusClickPosition(event) {
      this.clickX =
        document.documentElement.clientWidth - 420 < event.clientX
          ? document.documentElement.clientWidth - 420
          : event.clientX;
      this.clickY =
        document.documentElement.clientHeight - 300 < event.clientY
          ? document.documentElement.clientHeight - 300
          : event.clientY;
      this.focus();
    },
    focus() {
      if (this.editable === false) {
        return;
      }
      if (this.type === "price" && this.modelValueData?.multiple) {
        this.modelValueData = {
          amount: 0,
          currency:
            this.modelValueData.values[
              Object.keys(this.modelValueData.values)[0]
            ].value.currency,
        };
      }

      if (this.modelValue === undefined) {
        this.modelValueData = "";
        this.$emit("update:modelValue", this.modelValueData);
      }
      this.edit = true;
      const _this = this;
      setTimeout(function () {
        if (_this.type === "enum" && _this.edit) {
          _this.$refs.imp2.$refs.search.focus();
        } else if (_this.type === "color" && _this.edit) {
          _this.$refs.imp5.$refs.search.focus();
        } else if (_this.type === "category" && _this.edit) {
          _this.$refs.imp4.$refs.search.focus();
        } else if (_this.type === "phone" && _this.edit) {
          if (!_this.extraMod) {
            _this.extraMod = intlTelInput(_this.$refs.imp, {
              separateDialCode: true,
              utilsScript:
                "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.15/js/utils.min.js",
            });
            _this.$refs.imp.addEventListener("open:countrydropdown", () => {
              _this.focusOn = true;
              const viewportOffset = _this.$refs.imp.getBoundingClientRect();
              setTimeout(function () {
                document.getElementsByClassName(
                  "iti__country-list"
                )[0].style.top = viewportOffset.top + 21 + "px";
                document.getElementsByClassName(
                  "iti__country-list"
                )[0].style.left = viewportOffset.left + 1 + "px";
              }, 100);
            });
            _this.$refs.imp.addEventListener("close:countrydropdown", () => {
              _this.focusOn = false;
            });
          }
        } else if (_this.type === "string" || _this.type === "number") {
          _this.$refs.imp.focus();
        } else if (_this.type === "colorPicker") {
          _this.$refs.imp.click();
        }
      }, 100);
    },
    input(val) {
      if (this.type === "phone") {
        this.width = val?.length * 6 + 120 + "px";
      } else if (this.type !== "enum") {
        this.width = val?.length * 6 + 30 + "px";
      } else if (this.type === "color") {
        this.width = val?.length * 6 + 160 + "px";
      }
      //  this.$emit("update:modelValue", val);
      // this.$emit("update", val);
    },
    change(val) {
      if (this.type === "colorPicker") {
        val = val.replace("#", "");
      }
      this.$emit("update:modelValue", val);
      if (this.type === "colorPicker") {
        this.$emit("update", val);
      }
    },
    changeBool(val) {
      this.modelValueData = val;
      this.$emit("update:modelValue", this.modelValueData);
      this.$emit("update", this.modelValueData);
    },
    modelValueDataChange() {
      this.$emit("update:modelValue", this.modelValueData);
      this.$emit("update", this.modelValueData);
    },
    formatDate(val, time = true, relative = true) {
      return date.format(val, time, relative);
    },
  },
};
</script>
