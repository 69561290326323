<template>
  <Login v-if="currentRouteName === 'Login'" />
  <ForgottenPassword v-else-if="currentRouteName === 'ForgottenPassword'" />
  <SetNewPassword v-else-if="currentRouteName === 'SetNewPassword'" />
  <Register v-else-if="currentRouteName === 'Register'" />
  <AcceptLogin v-else-if="currentRouteName === 'AcceptLogin'" />
  <FirstSteps v-else-if="currentRouteName === 'FirstSteps'" />
  <Layout v-else />

  <div
    class="position-fixed bottom-0 end-0 p-3 z-index-1070"
    v-if="apiError || apiSuccess || apiLoading?.length > 0"
  >
    <div
      ref="apiError"
      class="toast hide"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
    >
      <div class="toast-header">
        <strong class="me-auto">{{
          apiError?.title !== undefined ? apiError?.title : "Error"
        }}</strong>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="toast"
          aria-label="Close"
        ></button>
      </div>
      <div class="toast-body">
        {{ apiError?.message }}
        <ul v-if="apiError?.errors">
          <li v-for="error in apiError.errors" :key="error">
            {{ error[0] }}
          </li>
        </ul>
      </div>
    </div>
    <div
      ref="apiSuccess"
      class="toast hide"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
    >
      <div class="toast-header">
        <strong class="me-auto">Success</strong>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="toast"
          aria-label="Close"
        ></button>
      </div>
      <div class="toast-body">
        {{ apiSuccess?.message || "The changes have been saved" }}
      </div>
    </div>
    <template v-for="toast in apiLoading" :key="toast.key">
      <div
        class="toast show"
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
      >
        <div class="toast-header">
          <div class="spinner-border text-primary me-3" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          <strong class="me-auto">{{ toast.message }}</strong>
        </div>
      </div>
    </template>
  </div>
  <Modal
    v-if="modal !== null && modal !== undefined"
    v-model:component="modal.component"
    v-model:app-id="modal.appId"
    v-model:title="modal.title"
    v-model:data="modal.data"
    ref="modal"
  />
</template>
<script>
import { defineAsyncComponent } from "vue";
//import Layout from "./views/Layout";
const Layout = defineAsyncComponent(() => import("./views/Layout.vue"));
const Login = defineAsyncComponent(() => import("./views/login/Login.vue"));
const ForgottenPassword = defineAsyncComponent(() =>
  import("./views/login/ForgottenPassword.vue")
);
const SetNewPassword = defineAsyncComponent(() =>
  import("./views/login/SetNewPassword.vue")
);
const Register = defineAsyncComponent(() =>
  import("./views/login/Register.vue")
);
const AcceptLogin = defineAsyncComponent(() =>
  import("./views/login/AcceptLogin.vue")
);
const FirstSteps = defineAsyncComponent(() =>
  import("./views/login/FirstSteps.vue")
);
import { useStore } from "vuex";
import Modal from "@/components/modals/Modal";
const bootstrap = require("bootstrap");

export default {
  components: {
    FirstSteps,
    Modal,
    AcceptLogin,
    Register,
    SetNewPassword,
    ForgottenPassword,
    Login,
    Layout,
  },
  beforeCreate() {
    this.$store.commit("initialiseStore");
  },
  data() {
    const store = useStore();
    return {
      store: store,
      modal: null,
    };
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    apiError() {
      return this.store.state.apiError;
    },
    apiSuccess() {
      return this.store.state.apiSuccess;
    },
    apiLoading() {
      return this.store.state.apiLoading;
    },
    lastModal() {
      return this.store.state.lastModal;
    },
    language() {
      return this.store.state.user?.language_code;
    },
  },
  watch: {
    lastModal() {
      if (this.modal !== null && this.lastModal === null) {
        this.$refs.modal.hideModal();
      } else {
        this.modal = this.lastModal;
      }
    },
    language() {
      this.$i18n.locale = this.language;
    },
    apiError() {
      if (this.apiError) {
        setTimeout(() => {
          let toast = new bootstrap.Toast(this.$refs.apiError);
          toast.show();
        }, 400);
      }
    },
    apiSuccess() {
      if (this.apiSuccess) {
        setTimeout(() => {
          let toast = new bootstrap.Toast(this.$refs.apiSuccess);
          toast.show();
        }, 400);
      }
    },
  },
};
</script>
